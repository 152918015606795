<template>
  <div class="not_found f_14">
    <m-nav
      :navInform="false"
      ref="nav"
      :page="'Home'"
      :navigationBar="false"
    ></m-nav>
    <p>Not Found (#404)</p>
    <div class="alert alert-danger">Page not found.</div>
    <p class="m_10">
      The above error occurred while the Web server was processing your request.
    </p>
    <p>Please contact us if you think this is a server error. Thank you.</p>
    <new-footer-m></new-footer-m>
  </div>
</template>

<script>
import MNav from "@/components/M/MNav.vue";
import NewFooterM from "@/components/M/NewFooterM.vue";
export default {
  components: { MNav, NewFooterM },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.not_found {
  .alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #e0caca;
    border-radius: 4px;
  }
  .m_10 {
    margin-bottom: 20px;
  }
}
</style>